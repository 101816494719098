import { template as template_222f7a3130ea4e13bf3caa5f914dc1e1 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import icon from "discourse/helpers/d-icon";
import { i18n } from "discourse-i18n";
const BulkSelectCell = template_222f7a3130ea4e13bf3caa5f914dc1e1(`
  <th class="bulk-select topic-list-data">
    {{#if @canBulkSelect}}
      <button
        {{on "click" @bulkSelectHelper.toggleBulkSelect}}
        title={{i18n "topics.bulk.toggle"}}
        class="btn-flat bulk-select"
      >
        {{icon "list-check"}}
      </button>
    {{/if}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
