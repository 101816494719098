import { template as template_84afc15d4bc046189215f83c3edbaef2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_84afc15d4bc046189215f83c3edbaef2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
