import { template as template_e3c1da5af70b4234817ad673d832bd5f } from "@ember/template-compiler";
const FKControlMenuContainer = template_e3c1da5af70b4234817ad673d832bd5f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
