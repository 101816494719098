import { template as template_a0c86e70f4dd4164b77b4377d4c9e9cf } from "@ember/template-compiler";
const EmptyState = template_a0c86e70f4dd4164b77b4377d4c9e9cf(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
