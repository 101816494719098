import { template as template_f333e384c96945229c1eb05031b4f526 } from "@ember/template-compiler";
const WelcomeHeader = template_f333e384c96945229c1eb05031b4f526(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
